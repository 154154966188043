import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { setShowingDialogState } from 'src/redux/slices/showDialog.slice';
import { RootState } from 'src/redux/store';
import { fpost } from '../../../lib/fetch';
import { configDouble, dataDouble } from './DoubleChart';
import { config as configFlowRate, data as dataFlowRate } from './FlowRateConfig';
import './WaterLevel.css';
import { configWater, dataWater } from './WaterLevelConfig';
import { Button } from '../../../lib/components/Button.component';
import { ResponseListDocuments } from '../../dashboard/dto/response';
import { DeviceRecord } from '../../dashboard/dto/device/deviceRecord';
import { toasting } from '../../../lib/components/Toast.component';
// @ts-ignore
import DatePicker from 'react-datepicker';
// @ts-ignore
import { CSVLink } from 'react-csv';
import styles from './DialogWaterLevel.module.css';
import { configVelocity, dataVelocity } from './VelocityConfig';
import { AiOutlineLoading, AiOutlineReload } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';

export function DialogWaterLevel() {
  const showDialog = useSelector((state: RootState) => state.showDialogReducer.showDialogWaterLevel);
  const dataDialog = useSelector((state: RootState) => state.showDialogReducer.position);
  const isMap = useSelector((state: RootState) => state.showDialogReducer.isMap);
  const isWaterLevel = useSelector((state: RootState) => state.showDialogReducer.isWaterLevel);
  const isFlowRate = useSelector((state: RootState) => state.showDialogReducer.isFlowRate);

  let date, firstDay, lastDay;
  date = new Date();
  firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const csvHeaders = ['SID', 'CNT', 'RF', 'VOL', 'DIS', 'PUBLISH', 'WATER_LEVEL', 'VELOCITY', 'FLOW_RATE'];
  const [csvData, setCsvData] = useState<any[]>([]);
  const [downloadCsvProcessing, setDownloadCsvProcessing] = useState(false);
  const [active, setActive] = useState(1);
  const [disabledSwitchButton, setDisabledSwitchButton] = useState(false);
  const [dateRange, setDateRange] = useState([firstDay, lastDay]);
  const [startDate, endDate] = dateRange;
  const [isReloading, setIsReloading] = useState(false);
  const csvLinkRef = useRef<any>(null);

  const setChartLabelString = (action: number) => {
    let label = '';
    let month: number | string = new Date().getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month;
    // convert to two digits

    const year = new Date().getFullYear();
    const day = new Date().getDate();
    const dow = new Date().getDay() - 1;

    const startDayOfWeek = new Date().getDate() - dow;
    const endDayOfWeek = startDayOfWeek + 6;

    switch (action) {
      case 1:
        label = `${year}年`;
        break;
      case 2:
        label = `${year}/${month}月`;
        break;
      case 3:
        label = `${year}/${month}/${startDayOfWeek} - ${year}/${month}/${endDayOfWeek}週`;
        break;
      case 4:
        label = `${year}/${month}/${day}日`;
        break;
    }

    configDouble.options.scales.xAxes[0].scaleLabel.labelString = label;
    configWater.options.scales.xAxes[0].scaleLabel.labelString = label;
    configFlowRate.options.scales.xAxes[0].scaleLabel.labelString = label;
    configVelocity.options.scales.xAxes[0].scaleLabel.labelString = label;
  };

  const getAdjustedValue = (min:number, max:number) => {
    const range = max - min;
    const scalingFactor = 0.4;

    const adjustedMin = min - range * scalingFactor;
    const adjustedMax = max + range * scalingFactor;

    return [
      adjustedMin,
      adjustedMax,
    ];
  }

  const getYear = async () => {
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    setDisabledSwitchButton(true);

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;
    dataVelocity.labels = labels;

    const start = new Date();
    start.setMonth(0);
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setMonth(11);
    end.setDate(31);
    end.setHours(23, 59, 59, 999);

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: start.getTime().toString() },
          ':timeEnd': { S: end.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_YEAR',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items.reverse();
    dataDouble.datasets[0].data = Array.from({ length: 12 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 12 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 12 }, () => null);

    dataFlowRate.datasets[0].data = Array.from({ length: 12 }, () => null);
    dataWater.datasets[0].data = Array.from({ length: 12 }, () => null);
    dataVelocity.datasets[0].data = Array.from({ length: 12 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const month = new Date(parseInt(item.CREATE_AT.S)).getMonth();
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[month] = waterLevel;
        dataWater.datasets[0].data[month] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[month] = flowRate;
        dataFlowRate.datasets[0].data[month] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[month - 1] = velocity;
        dataVelocity.datasets[0].data[month - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setDisabledSwitchButton(false);
  };

  const getMonth = async () => {
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;
    setDisabledSwitchButton(true);

    const current = new Date();
    const daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();

    const labels = Array.from({ length: daysInMonth }, (_, index) => (index + 1).toString());
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: new Date(current.getFullYear(), current.getMonth(), 1).getTime().toString() },
          ':timeEnd': { S: current.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: daysInMonth }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: daysInMonth }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 24 }, () => null);

    dataWater.datasets[0].data = Array.from({ length: daysInMonth }, () => null);
    dataVelocity.datasets[0].data = Array.from({ length: 24 }, () => null);
    dataFlowRate.datasets[0].data = Array.from({ length: daysInMonth }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dom = new Date(parseInt(item.CREATE_AT.S)).getDate() - 1;

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dom - 1] = waterLevel;
        dataWater.datasets[0].data[dom - 1] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(minWaterLevel, waterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dom - 1] = flowRate;
        dataFlowRate.datasets[0].data[dom - 1] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dom - 1] = velocity;
        dataVelocity.datasets[0].data[dom - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setDisabledSwitchButton(false);
  };

  const getWeek = async () => {
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    setDisabledSwitchButton(true);
    const labels = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    const current = new Date();
    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    const firstDay = startDay.getTime() - ((new Date().getDay() === 0 ? 7 : new Date().getDay() - 1)) * 24 * 60 * 60 * 1000;


    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: firstDay.toString() },
          ':timeEnd': { S: current.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: 7 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 7 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 24 }, () => null);

    dataFlowRate.datasets[0].data = Array.from({ length: 7 }, () => null);
    dataWater.datasets[0].data = Array.from({ length: 7 }, () => null);
    dataVelocity.datasets[0].data = Array.from({ length: 24 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dow = new Date(parseInt(item.CREATE_AT.S)).getDay() - 1;

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dow - 1] = waterLevel;
        dataWater.datasets[0].data[dow - 1] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dow - 1] = flowRate;
        dataFlowRate.datasets[0].data[dow - 1] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }

      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dow - 1] = velocity;
        dataVelocity.datasets[0].data[dow - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setDisabledSwitchButton(false);
  };

  const getDay = async () => {
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;
    setDisabledSwitchButton(true);

    const labels = Array.from({ length: 24 }, (_, index) => ((index + 9) % 24).toString());
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: startDay.getTime().toString() },
          ':timeEnd': { S: new Date().getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_DAY',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: 24 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 24 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 24 }, () => null);
    dataFlowRate.datasets[0].data = Array.from({ length: 24 }, () => null);
    dataWater.datasets[0].data = Array.from({ length: 24 }, () => null);
    dataVelocity.datasets[0].data = Array.from({ length: 24 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const hour = new Date(parseInt(item.CREATE_AT.S)).getUTCHours();
      if (new Date(parseInt(item.CREATE_AT.S)).getUTCDay() !== new Date().getUTCDay()) continue;
      const idx = hour - 1 - 1; // 1 for index, 1 for hours
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[idx] = waterLevel;
        dataWater.datasets[0].data[idx] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[idx] = flowRate;
        dataFlowRate.datasets[0].data[idx] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }

      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[idx] = velocity;
        dataVelocity.datasets[0].data[idx] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setDisabledSwitchButton(false);
  };

  const getRecordCsv = async () => {
    setDownloadCsvProcessing(true);
    const [startDate, endDate] = dateRange;
    if (dateRange && startDate && endDate) {
      firstDay = new Date(startDate);
      lastDay = new Date(endDate);
    } else {
      date = new Date();
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    const deviceId = dataDialog.deviceId;
    if (!deviceId) {
      return;
    }
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeEnd': { S: lastDay.getTime().toString() },
          ':timeStart': { S: firstDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any, ResponseListDocuments<DeviceRecord>>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items;
    const records: any[] = [];
    items?.forEach((it: any) => {
      records.push([
        it.SID?.S,
        it.CNT?.S,
        it.RF?.S,
        it.VOL?.S,
        it.DIS?.S,
        it.Publish?.S,
        it.WATER_LEVEL?.S,
        it.VELOCITY?.S,
        it.FLOW_RATE?.S,
      ]);
    });
    setCsvData(records);
    setDownloadCsvProcessing(false);
    if (!records.length) {
      toasting({ children: 'データがありません', containerProps: { className: 'border-blue-600' } });
    }
  };

  const handleReload = async (active: number) => {
    setIsReloading(true);
    switch (active) {
      case 1:
        await getYear();
        break;
      case 2:
        await getMonth();
        break;
      case 3:
        await getWeek();
        break;
      case 4:
        await getDay();
        break;
      default:
        break;
    }
    setIsReloading(false);
  };

  useEffect(() => {
    if (csvData.length > 0) {
      console.log('start download');
      console.log(csvLinkRef.current.link);
      csvLinkRef.current.link.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (!showDialog) return;
    const start = async () => {
      setActive(4);
      setChartLabelString(4);
      await getDay();
    };
    start();
  }, [showDialog]);

  const dispatch = useDispatch();

  return showDialog ? (
    <div
      className="absolute top-0 h-full w-full text-center"
      style={{ paddingRight: isMap ? '250px' : '0', background: 'rgba(0,0,0,0.5)', zIndex: 1000 }}
      onMouseMove={(e) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
      onClick={(e) => {
        if (csvLinkRef.current) {
          // Nếu click vào link, không ngăn chặn
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}>
      <div
        id="DialogWaterLevel"
        className="relative m-auto w-full max-w-3xl md:h-auto"
        style={{ transform: 'translateY(40%)' }}>
        {/* <!-- Modal content --> */}
        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
          {/* <!-- Modal header --> */}
          <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 ">
            <div className="inline border-2 " style={{ borderColor: '#015CAC' }}>
              <button
                onClick={(e) => {
                  setActive(1);
                  setChartLabelString(1);
                  getYear();
                }}
                className={(active == 1 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                disabled={disabledSwitchButton}>
                年
              </button>
              <button
                onClick={(e) => {
                  setActive(2);
                  setChartLabelString(2);
                  getMonth();
                }}
                className={(active == 2 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                disabled={disabledSwitchButton}>
                月
              </button>
              <button
                onClick={(e) => {
                  setActive(3);
                  setChartLabelString(3);
                  getWeek();
                }}
                className={(active == 3 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                disabled={disabledSwitchButton}>
                週
              </button>
              <button
                onClick={(e) => {
                  setActive(4);
                  setChartLabelString(4);
                  getDay();
                }}
                className={(active == 4 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                disabled={disabledSwitchButton}>
                日
              </button>
            </div>
            <DatePicker
              className="ml-3 mt-2 w-1/3 rounded-lg border-inherit"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
            <div className="ml-5">
              <Button
                className="inline bg-black text-white"
                onClick={getRecordCsv}
                isProcessing={downloadCsvProcessing}>
                CSVダウンロード
              </Button>
              <CSVLink
                ref={csvLinkRef}
                asyncOnClick={true}
                className=""
                data={csvData}
                headers={csvHeaders}
                filename="Raw_data.csv"
              />
            </div>
            <AiOutlineReload
              className={twMerge(
                'ml-5 cursor-pointer text-[#015CAC] hover:scale-110',
                isReloading ? 'animate-spin' : 'animate-none',
              )}
              onClick={() => {
                handleReload(active);
              }}
              strokeWidth={10}
              title="リセット"
              size={24}
            />
            <button
              type="button"
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              // data-modal-toggle="defaultModal"
              onClick={(e) => {
                dispatch(setShowingDialogState({ showDialogWaterLevel: false, isMap: true }));
              }}>
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="space-y-6 p-6">
            {/* {isWaterLevel && !isFlowRate ? <Line data={dataWater} options={configWater.options} /> : ''}
            {isFlowRate && !isWaterLevel ? <Line data={dataFlowRate} options={configFlowRate.options} /> : ''}
            {(isFlowRate && isWaterLevel) || !isMap ? <Line data={dataDouble} options={configDouble.options} /> : ''} */}
            {<Line data={dataDouble} options={configDouble.options} />}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
