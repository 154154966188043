import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Button } from '../../../../lib/components/Button.component';
import { Select } from '../../../../lib/components/Select.component';
import { Table } from '../../../../lib/components/Table.component';
import { fget, fpost } from '../../../../lib/fetch';
import { configDouble, dataDouble } from '../../../map-dialog/components/DoubleChart';
import { AreaEntity } from '../../dto/area/area.entity';
import { DeviceEntity } from '../../dto/device/device.entity';
import { DeviceRecord } from '../../dto/device/deviceRecord';
import { ResponseDocument, ResponseGetDocument, ResponseListDocuments } from '../../dto/response';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './raw.css';
// @ts-ignore
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { Pagination } from 'src/lib/components/Pagination.component';
import { toasting } from 'src/lib/components/Toast.component';
import { dataVelocity } from '../../../map-dialog/components/VelocityConfig';
import { AiOutlineReload } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';

export function RawPage() {
  const [record, setRecord] = useState<ResponseListDocuments<DeviceRecord>>();
  const headers = ['SID', 'CNT', 'RF', 'VOL', 'DIS', 'PUBLISH', 'WATER_LEVEL', 'VELOCITY', 'FLOW_RATE'];
  const [csvData, setCsvData] = useState<any[]>([]);
  const csvLinkRef = useRef<any>(null);
  const [isReloading, setIsReloading] = useState(false);

  const [active, setActive] = useState(1);
  let date, firstDay, lastDay;
  date = new Date();
  firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [downloadCsvProcessing, setDownloadCsvProcessing] = useState(false);
  const [getRecordProcessing, setGetRecordProcessing] = useState(false);

  const [dateRange, setDateRange] = useState([firstDay, lastDay]);
  const [startDate, endDate] = dateRange;

  const [trigger, setTrigger] = useState(false);

  const eSelectRegionName = useRef<HTMLSelectElement>(null);
  const eSelectDeviceName = useRef<HTMLSelectElement>(null);

  const { id } = useParams();
  const [data, setData] = useState<{
    deviceInit?: ResponseDocument<DeviceEntity>;
    areas?: ResponseListDocuments<AreaEntity>;
    devices?: ResponseListDocuments<DeviceEntity>;
  }>();

  const setChartLabelString = (action: number) => {
    let label = '';
    let month: number | string = new Date().getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month;
    // convert to two digits

    const year = new Date().getFullYear();
    const day = new Date().getDate();
    const dow = new Date().getDay() - 1;

    const startDayOfWeek = new Date().getDate() - dow;
    const endDayOfWeek = startDayOfWeek + 6;

    switch (action) {
      case 1:
        label = `${year}年`;
        break;
      case 2:
        label = `${year}/${month}月`;
        break;
      case 3:
        label = `${year}/${month}/${startDayOfWeek} - ${year}/${month}/${endDayOfWeek}週`;
        break;
      case 4:
        label = `${year}/${month}/${day}日`;
        break;
    }

    configDouble.options.scales.xAxes[0].scaleLabel.labelString = label;
  };

  const getAdjustedValue = (min:number, max:number) => {
    const range = max - min;
    const scalingFactor = 0.4;

    const adjustedMin = min - range * scalingFactor;
    const adjustedMax = max + range * scalingFactor;

    return [
      adjustedMin,
      adjustedMax,
    ];
  }

  const getYear = async () => {
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) return;

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    dataDouble.labels = labels;

    const start = new Date();
    start.setMonth(0);
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setMonth(11);
    end.setDate(31);
    end.setHours(23, 59, 59, 999);

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: start.getTime().toString() },
          ':timeEnd': { S: end.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_YEAR',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: 12 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 12 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 12 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const month = new Date(parseInt(item.CREATE_AT?.S)).getMonth();
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[month] = waterLevel;
        maxWaterLevel = Math.max(waterLevel, maxWaterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[month] = flowRate;
        maxFlowRate = Math.max(flowRate, maxFlowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[month - 1] = velocity;
        dataVelocity.datasets[0].data[month - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    setTrigger(!trigger);
  };

  const getMonth = async () => {
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) return;

    const current = new Date();
    const daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();

    const labels = Array.from({ length: daysInMonth }, (_, index) => (index + 1).toString());
    dataDouble.labels = labels;

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: new Date(current.getFullYear(), current.getMonth(), 1).getTime().toString() },
          ':timeEnd': { S: current.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: daysInMonth }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: daysInMonth }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 24 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dom = new Date(parseInt(item.CREATE_AT?.S)).getDate() - 1;

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dom - 1] = waterLevel;

        minWaterLevel = Math.min(waterLevel, minWaterLevel);
        maxWaterLevel = Math.max(waterLevel, maxWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dom - 1] = flowRate;

        maxFlowRate = Math.max(flowRate, maxFlowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dom - 1] = velocity;
        dataVelocity.datasets[0].data[dom - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }
    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setTrigger(!trigger);
  };

  const getWeek = async () => {
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) return;

    const labels = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];
    dataDouble.labels = labels;

    const current = new Date();
    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    const firstDay = startDay.getTime() - ((new Date().getDay() === 0 ? 7 : new Date().getDay() - 1)) * 24 * 60 * 60 * 1000;
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: firstDay.toString() },
          ':timeEnd': { S: current.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: 7 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 7 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 7 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dow = new Date(parseInt(item.CREATE_AT?.S)).getDay() - 1;

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dow - 1] = waterLevel;

        minWaterLevel = Math.min(waterLevel, minWaterLevel);
        maxWaterLevel = Math.max(waterLevel, maxWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dow - 1] = flowRate;

        maxFlowRate = Math.max(flowRate, maxFlowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dow - 1] = velocity;
        dataVelocity.datasets[0].data[dow - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);

    setTrigger(!trigger);
  };

  const getDay = async () => {
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) return;
    const labels = Array.from({ length: 24 }, (_, index) => ((index + 9) % 24).toString());
    dataDouble.labels = labels;

    const startDay = new Date();
    startDay.setHours(0, 0, 0, 0);

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: startDay.getTime().toString() },
          ':timeEnd': { S: new Date().getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_DAY',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    dataDouble.datasets[0].data = Array.from({ length: 24 }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: 24 }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: 24 }, () => null);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const hour = new Date(parseInt(item.CREATE_AT?.S)).getUTCHours();
      if (new Date(parseInt(item.CREATE_AT?.S)).getUTCDay() !== new Date().getUTCDay()) continue;
      const idx = hour - 1 - 1; // 1 for index, 1 for hours
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[idx] = waterLevel;

        maxWaterLevel = Math.max(waterLevel, maxWaterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[idx] = flowRate;

        maxFlowRate = Math.max(flowRate, maxFlowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[idx] = velocity;
        dataVelocity.datasets[0].data[idx] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
    configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);

    configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
    configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);

    configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
    configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    setTrigger(!trigger);
  };

  const getRecord = async (page = 1) => {
    const [startDate, endDate] = dateRange;
    if (dateRange && startDate && endDate) {
      firstDay = new Date(startDate);
      lastDay = new Date(endDate);
    } else {
      date = new Date();
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) {
      return;
    }
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeEnd': { S: lastDay.getTime().toString() },
          ':timeStart': { S: firstDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        Limit: page * 100,
        TableName: 'DEVICE_RECORD',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any, ResponseListDocuments<DeviceRecord>>('api/device/record/query', {
      body: query,
    });

    setRecord(data[1]);
  };

  const handleReload = async (active: number) => {
    setIsReloading(true);
    switch (active) {
      case 1:
        await getYear();
        break;
      case 2:
        await getMonth();
        break;
      case 3:
        await getWeek();
        break;
      case 4:
        await getDay();
        break;
      default:
        break;
    }
    setIsReloading(false);
  };

  const getRecordClick = async () => {
    setGetRecordProcessing(true);
    await getRecord();
    setGetRecordProcessing(false);
  };

  const getRecordCsv = async () => {
    setDownloadCsvProcessing(true);
    const [startDate, endDate] = dateRange;
    if (dateRange && startDate && endDate) {
      firstDay = new Date(startDate);
      lastDay = new Date(endDate);
    } else {
      date = new Date();
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    const deviceId = eSelectDeviceName?.current?.value;
    if (!deviceId) {
      return;
    }
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeEnd': { S: lastDay.getTime().toString() },
          ':timeStart': { S: firstDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any, ResponseListDocuments<DeviceRecord>>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items;
    const records: any[] = [];
    items?.forEach((it: any) => {
      records.push([
        it.SID?.S,
        it.CNT?.S,
        it.RF?.S,
        it.VOL?.S,
        it.DIS?.S,
        it.Publish?.S,
        it.WATER_LEVEL?.S,
        it.VELOCITY?.S,
        it.FLOW_RATE?.S,
      ]);
    });
    setCsvData(records);
    setDownloadCsvProcessing(false);
    if (!records.length) {
      toasting({ children: 'データがありません', containerProps: { className: 'border-blue-600' } });
    }
  };

  useEffect(() => {
    if (csvData.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [csvData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const areaResponse = await fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas');

        let device = undefined;
        const areaName = areaResponse[1].Items?.[0].AREA_NAME?.S;

        if (id) {
          const res = await fget<unknown, ResponseGetDocument<DeviceEntity>>(`api/devices/${id}`);

          device = res[1].Item;
        } else {
          const res = await fget<unknown, ResponseListDocuments<DeviceEntity>>(
            `api/deviceByArea?AREA_NAME=${areaName}`,
            {},
          );

          device = res[1].Items?.[0];
        }

        const newAreaName = device?.AREA_NAME?.S;

        const devicesResponse = await fget<unknown, ResponseListDocuments<DeviceEntity>>(
          `api/deviceByArea?AREA_NAME=${newAreaName}`,
        );

        setData({
          areas: areaResponse[1],
          devices: devicesResponse[1],
          deviceInit: device,
        });
      } catch (error) {
        // Handle errors here
        toasting({ children: '認証に失敗しました', containerProps: { className: 'border-red-600' } });
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const setDay = async () => {
      // sleep 3s
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setChartLabelString(4);
      setActive(4);
      await getDay();
    };
    setDay();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 text-2xl">生データ表示</h1>
      <span
        className="w-full border-b border-slate-800"
        style={{
          minWidth: '24rem',
        }}></span>

      <div className="flex w-full flex-auto flex-wrap">
        <div className="flex-auto">
          {data?.deviceInit && (
            <Select
              onChange={(e) => {
                fget(`api/deviceByArea?AREA_NAME=${e.target.value}`, {}).then((devices) => {
                  setData((prev) => ({ ...prev, devices: devices[1] }));
                });
              }}
              defaultValue={data.deviceInit.AREA_NAME?.S}
              labelProps={{ value: '地域名' }}
              containerProps={{
                className: 'mt-4 w-1/3 basis-full',
                style: {
                  minWidth: '24rem',
                },
              }}
              required
              ref={eSelectRegionName}>
              {data?.areas?.Items?.map((region, i) => (
                <option key={`Option-` + i} value={region.AREA_NAME?.S}>
                  {region.AREA_NAME?.S}
                </option>
              ))}
            </Select>
          )}

          {data?.deviceInit && (
            <Select
              defaultValue={data?.deviceInit?.DEVICE_ID?.S}
              labelProps={{ value: 'ディバイス名' }}
              containerProps={{
                className: 'mt-4 w-1/3',
                style: {
                  minWidth: '24rem',
                },
              }}
              required
              ref={eSelectDeviceName}>
              {data?.devices?.Items?.map((device, i) => (
                <option key={`Option-` + i} value={device.DEVICE_ID?.S}>
                  {device.DEVICE_NAME?.S}
                </option>
              ))}
            </Select>
          )}
          <p className="datepicker__title mt-4"> 設置日時 </p>
          <DatePicker
            className="mt-2 w-1/3 rounded-lg border-inherit"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update: any) => {
              setDateRange(update);
            }}
            isClearable={true}
          />

          <div className="mt-1">
            <Button
              onClick={getRecordClick}
              className="mr-3 mt-2 inline bg-black text-xs text-white"
              isProcessing={getRecordProcessing}>
              生データを確認
            </Button>

            <Button className="inline bg-black text-white" onClick={getRecordCsv} isProcessing={downloadCsvProcessing}>
              CSVダウンロード
            </Button>
            <CSVLink
              ref={csvLinkRef}
              asyncOnClick={true}
              className=""
              data={csvData}
              headers={headers}
              filename="Raw_data.csv"
            />
          </div>
        </div>

        {/*Chart*/}
        <div className="mb-10 ml-4 mt-4 max-w-2xl flex-auto">
          <div className="mb-3 flex select-none items-center">
            <div className="inline-block border-2 " style={{ borderColor: '#015CAC' }}>
              <button
                onClick={() => {
                  setActive(1);
                  setChartLabelString(1);
                  getYear();
                }}
                className={(active == 1 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}>
                年
              </button>
              <button
                onClick={() => {
                  setActive(2);
                  setChartLabelString(2);
                  getMonth();
                }}
                className={(active == 2 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                style={{}}>
                月
              </button>
              <button
                onClick={() => {
                  setActive(3);
                  setChartLabelString(3);
                  getWeek();
                }}
                className={(active == 3 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                style={{}}>
                週
              </button>
              <button
                onClick={() => {
                  setActive(4);
                  setChartLabelString(4);
                  getDay();
                }}
                className={(active == 4 ? 'btn-active' : 'btn') + ' inline px-4 text-center'}
                style={{}}>
                日
              </button>
            </div>
            <AiOutlineReload
              className={twMerge(
                'ml-5 cursor-pointer text-[#015CAC] hover:scale-110',
                isReloading ? 'animate-spin' : 'animate-none',
              )}
              onClick={() => {
                handleReload(active);
              }}
              strokeWidth={20}
              title="リセット"
              size={24}
            />
          </div>
          <Line data={dataDouble} options={configDouble.options} />
        </div>
      </div>

      <Pagination
        total={10000000}
        onPageChange={(page) => {
          if (!record?.Items?.length) {
            getRecord(1);
          } else {
            getRecord(page + 1);
          }
        }}
        hidePages
      />

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <span className="font-semibold">Total: {record?.Items?.length || ''}</span>
        <Table
          hideCheckbox
          headCellData={[
            'No',
            'GID',
            'SID',
            'CNT',
            'POS',
            'RF',
            'VOL',
            'DIS',
            'Publish',
            'WATER_LEVEL',
            'VELOCITY',
            'FLOW_RATE',
          ]}
          id="table-flow"
          cellData={record?.Items?.map((it, i) => [
            i + 1,
            it.GID?.S,
            it.SID?.S,
            it.CNT?.S,
            it.POS?.S,
            it.RF?.S,
            it.VOL?.S,
            it.DIS?.S,
            it.Publish?.S,
            it.WATER_LEVEL?.S,
            it.VELOCITY?.S,
            it.FLOW_RATE?.S,
          ])}
        />
      </div>
    </div>
  );
}
